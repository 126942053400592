export default {
  name: 'SavedContactLenses',
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  methods: {
    formatNumber (num) {
      return this.$parent.formatNumber(num)
    },
    reverseRouteName (str, defaultName = 'route-name') {
      return this.$parent.reverseRouteName(str, defaultName)
    }
  }
}
